import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid";
import Item from "../components/item";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Typography} from "@material-ui/core";
import Img from "gatsby-image";
import CardContent from "@material-ui/core/CardContent";
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
}));

const RetailPage = props => {
  const data = useStaticQuery(graphql`
      query {
          allImageSharp {
              edges {
                  node {
                      fluid(maxWidth: 700) {
                          aspectRatio
                          base64
                          originalImg
                          originalName
                          presentationHeight
                          presentationWidth
                          sizes
                          src
                          srcSetWebp
                          srcSet
                          srcWebp
                          tracedSVG
                      }
                  }
              }
          }
      }
  `)
  const { allImageSharp: { edges } } = data;
  const classes = useStyles();

  function getImage(name) {
    let image = "";

    edges.forEach(edge => {
      if (name === edge.node.fluid.originalName) {
        image = edge.node.fluid;
      }
    });

    return image
  }

  return (
    <div>
      <Layout>
        <div className={classes.root}>
          <CssBaseline />
          <SEO title="Retail products" />
          <div className={classes.toolbar} />
          <Typography variant="h2" component="h1">
            Ad Specialties          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Maximize your product and literature exposure with a clear display fixtures. Clear Industries has a host of clear display solutions which provide maximum visibility for products and literature. Whether your need is common or custom in design, we can provide you an attractive solution at factory-direct pricing.

            <br/>
            <br/>
            <Typography variant="h5" component="p">Contact</Typography>
            Toll-Free 800-383-0458<br/>
            Local 612-521-1172<br/>
            After Hours 612-669-7364<br/>
            You may fax inquiries and orders to us at 612-521-1447.<br/>
          </Typography>
          <br/>
          <Typography variant="h4" component="h1">
          Sign Holders & Table Tents
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Available in a variety of styles, sizes and thicknesses. Clear Industries' sign holders and table tents are a clear display solution for your signage needs.

            You can economically enhance your signage appeal with polished edges and custom imprinting.

            You have a custom size sign? No problem, we can provide a perfect fit with our custom manufacturing capabilities!

            Clear presentation with factory direct pricing is the right solution.

            Call Today - Our customer service team is ready to assist you!


          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("sh-123-holders.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("sh-sign-holders.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-slantBack.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-top-load.jpg")} />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Img fluid={getImage("sh-bottom-load.jpg")} />
            </Grid>

          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Literature Holders & Brochure Holders
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Clear Industries provides a host of literature and brochure holder solutions for countertop, slat wall, grid wall and drill wall mount applications.

            Whether your literature is common or custom in size, we can produce brochure holders to perfectly fit your needs.

            Common sized brochure holders accommodate 3 3/4" x 8 1/2" double fold brochures, single fold 5 1/2" x 8 1/2" and standard 8 1/2" x 11" literature.

            Custom imprinting is also available

            Clear presentation combined with factory direct pricing equates to great value for our customers.

            Call for a price quote today!
          </Typography>
          <br/>

          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("lh-free-b.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("lh-multi.jpg")} />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Img fluid={getImage("lh-rotate.jpg")} />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Img fluid={getImage("lh-free.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Combination Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            The clear solution for displaying printed ad along with brochures or business cards.

            Our combination displays feature a sign holder with an attached brochure or business card pocket.

            Style, shape and size - A pocket on the left or on the right, or perhaps along side your literature all together? Sitting on a countertop or mounted on a wall? We can provide an exact fit for your display needs.

            Greater Appeal - Consider custom imprinting to enhance your display's effectiveness.

            Call Today! Our customer service staff would be glad to assist you with design and pricing.

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("c-custom.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("c-custom-2.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>
          <Typography variant="h4" component="h1">
            Product Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Clear, customized displays can give your product the competitive edge. Clear Industries provides a host of product display solutions for countertop or wall mount applications.

            Cost, visual appeal, durability, all these are issues to consider when designing a product or literature display. We would be glad to assist you in this process. With nearly 20 years of design experience, we can help you maximize your display appeal.

            Make it yours with custom imprinting.

            The Clear Choice - Custom displays at factory direct pricing, that's a perfect fit!

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-calling.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-multi.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("pd-risers.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

          <Typography variant="h4" component="h1">
            Counter Top Displays
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            Whether for retail products or literature, Clear Industries provides a host of countertop display solutions.

            Have a design in mind that you need help developing? We would be glad to assist you in this process. With nearly 20 years of design and production experience, Clear Industries can help you with a custom fit display solution.

            You Name It! - Increase your name recognition with custom imprinting.

            Clearly Seen - Transparent countertop displays maximize your product and literature exposure.

          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-circle.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-display.jpg")} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("cd-steppers.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>


          <Typography variant="h4" component="h1">
            Business Card Holders
          </Typography>
          <br/>
          <Typography variant="p" component="p">
            What every desk and lobby should have.

            Choose from the styles shown or let us design a custom holder for you.
          </Typography>
          <br/>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={3}>
              <Img fluid={getImage("bc-holders.jpg")} />
            </Grid>
          </Grid>
          <br/>
          <Divider />
          <br/>

        </div>
      </Layout>
    </div>)
};

export default RetailPage;